.title {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    color: var(--light);
}

h3.title {
    font-size: 1.25em;
    font-weight: bold;
    text-align: center;
    color: var(--light);
}

.author {
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    color: var(--light);
}

.start-button {
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    padding: 20px;
    border-style: solid;
    border-color: #aaa;
    border-radius: 16px;
    border-width: 1px;
    color: #fff;
    text-decoration: none;
}

.start-button:after {
    text-decoration: none;
}

.start-button:hover {
    border-color: #fff;
    text-decoration: none;
}

.track-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-weight: normal;
    font-size: 0.75em;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 5px;
    margin-right: 5px;
}

.track-item:hover {
    font-weight: bold;
}

.track-item2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-weight: normal;
    font-size: 0.8em;
    position: absolute;
    left: 25%;
    width: 65%;
}

.track-item2:hover {
    font-weight: bold;
}

.suggested-track-ind {
    display: flex;
    height: 100%;
    width: 20px;
    align-items: 'center';
    justify-content: center;
}

.track-number {
    text-align: right;
    margin-right: 10px;
}

.track-length {
    width: 80px;
    text-align: right;
}

.track-title {
    flex: 1;
    text-align: left;
}

.option-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    font-weight: normal;
    font-size: 0.8em;
    margin-top: 3px;
    margin-bottom: 3px;
    margin-left: 20px;
    margin-right: 20px;
}
.option-item select {
    font-size: 1em;
    background-color: #000;
    color: var(--light);
}
.option-item select option {
    background-color: #000;
    color: var(--light);
}
.option-item:hover {
    font-weight: bold;
}
.option-title {
    flex: 1;
    text-align: left;
}
.option-value {
    width: 0.8em;
    height: 0.8em;
    border-width: 2px;
    border-style: solid;
    border-color: var(--light);
    align-items: center;
    justify-content: center;    
}

.song-info {
    position: absolute;
    left: 10px;
    top: 10px;
    width: 600px;
    max-width: 90%;
    max-height: 80%;
    background-color: #0005;
    backdrop-filter: blur(10px);
    padding-top: .5em;
    padding-bottom: 0em;
    border-radius: 1em;
    scrollbar-width: 40px;
    overflow: hidden;
}

.song-info h1 {
    margin-left: .5em;
    margin-right: .5em;
    margin-block-start: 0em;
    margin-block-end: .0em;
    font-size: 1.25em;
    text-align: center;
}

.album-notes-expand-button {
    background: transparent;
    color: var(--light);
    cursor: pointer;
    border: none;
    font-size: 1em;
    padding-top: 0px;
    padding-bottom: 0px;
}

.album-notes-expand-button:hover {
    background: #fff2;
}

.album-notes {
    direction: rtl;
    overflow-y: auto;
    scrollbar-color: #fff8 transparent;
    padding-left: 1em;
    padding-right: 1em;
}

.album-notes p {
    direction: ltr;
    margin-top: .5em;
    margin-bottom: .5em;
    font-size: calc(8px + .5em);
}

.image-attribution {
    position: absolute;
    left: 10px;
    bottom: 10px;
    max-width: 90%;
    max-height: 20%;
    background-color: #0005;
    backdrop-filter: blur(10px);
    padding: .5em;
    border-radius: .5em;
    scrollbar-width: 40px;
    font-size: 0.5em;
    color: var(--light);
    overflow-y: auto;
    scrollbar-color: #fff8 transparent;
}