
.anim-time-fast {
    animation-duration: 500ms;
}

.anim-time-normal {
    animation-duration: 1s;
}

.anim-time-slow {
    animation-duration: 2s;
}

.anim-time-extra-slow {
    animation-duration: 5s;
}

.anim-time-1-min {
    animation-duration: 60s;
}
.anim-time-2-min {
    animation-duration: 120s;
}
.anim-time-3-min {
    animation-duration: 180s;
}
.anim-time-4-min {
    animation-duration: 240s;
}
.anim-time-5-min {
    animation-duration: 300s;
}
.anim-time-6-min {
    animation-duration: 360s;
}
.anim-time-7-min {
    animation-duration: 360s;
}

/******************************
* Change from solid black background to 0 opacity background
*******************************/
.disolve-solid-black-bg {
    background-color: #000f;
}

@media (prefers-reduced-motion: no-preference) {
    .disolve-solid-black-bg {
        background-color: #0000;
        animation-name: disolve-solid-black-bg-anim;
        animation-timing-function: ease-in;
        animation-iteration-count: 1;
        animation-direction: forwards;
    }
}

@keyframes disolve-solid-black-bg-anim {
    from {
        background-color: #000f;
    }
    to {
        background-color: #0000;
    }
}

/******************************
* Pulsing Glowing effect
*******************************/
.spin-bg-slow {
    transform: rotate(0deg);
}

@media (prefers-reduced-motion: no-preference) {
  .spin-bg-slow {
    animation: 300s linear infinite spin-bg-anim;
  }
}

@keyframes spin-bg-anim {
  from {
      transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/******************************
* Pulsing Glowing effect
*******************************/
.glowing-bg {
    filter: blur(2px);
    position: absolute;
    left: 0;
    right: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .glowing-bg {
    animation: 2s ease-in-out infinite alternate glow-bg-anim;
  }
}

@keyframes glow-bg-anim {
  from {
      filter: blur(2px);
  }
  to {
      filter: blur(5px);
  }
}

/******************************
* Glowing effect
*******************************/
.glowing {
    filter: blur(1px);
}

@media (prefers-reduced-motion: no-preference) {
  .glowing {
    animation: 2s ease-in-out infinite alternate glow-anim;
  }
}

@keyframes glow-anim {
  from {
      filter: blur(1px);
  }
  to {
      filter: blur(3px);
  }
}

.glowing-blur {
    filter: blur(5px);
}

@media (prefers-reduced-motion: no-preference) {
  .glowing-blur {
    animation: 2s ease-in-out infinite alternate glow-blur-anim;
  }
}

@keyframes glow-blur-anim {
  from {
      filter: blur(5px);
  }
  to {
      filter: blur(20px);
  }
}


/******************************
* Change color/hue
*******************************/
.color-wheel {
    filter: blur(1px) sepia(0%) saturate(10) hue-rotate(0turn);
    position: relative;
}


@media (prefers-reduced-motion: no-preference) {
  .color-wheel {
    animation: 240s linear infinite color-wheel-anim;
  }
}

@keyframes color-wheel-anim {
  0% { filter: blur(1px) sepia(0%) saturate(10) hue-rotate(0turn); }
  10% { filter: blur(10px) sepia(50%) saturate(10) hue-rotate(.1turn); }
  20% { filter: blur(1px) sepia(100%) saturate(10) hue-rotate(.2turn); }
  30% { filter: blur(10px) sepia(100%) saturate(10) hue-rotate(.4turn); }
  40% { filter: blur(1px) sepia(100%) saturate(10) hue-rotate(.4turn); }
  50% { filter: blur(10px) sepia(100%) saturate(10) hue-rotate(.5turn); }
  60% { filter: blur(1px) sepia(100%) saturate(10) hue-rotate(.6turn); }
  70% { filter: blur(10px) sepia(100%) saturate(10) hue-rotate(.7turn); }
  80% { filter: blur(1px) sepia(100%) saturate(10) hue-rotate(.8turn); }
  90% { filter: blur(10px) sepia(50%) saturate(10) hue-rotate(.9turn); }
  100% { filter: blur(1px) sepia(0%) saturate(10) hue-rotate(1.0turn); }
}


/******************************
* Zoom way in
*******************************/
.zoom-in-transition-slow {
    filter: blur(0px);
    transform: scale(1);
    animation: zoom-in-anim 10s ease-in 1 forwards;
}

.zoom-in-transition-fast {
    filter: blur(0px);
    transform: scale(1);
    animation: zoom-in-anim 1s ease-in 1 forwards;
}

.zoom-in-transition-x {
    filter: blur(0px);
    transform: scale(1);
    animation-name: zoom-in-anim;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
    animation-direction: forwards;
}

@keyframes zoom-in-anim {
    from {
        filter: blur(0px);
        transform: scale(1);
        opacity: 1;
    }
    to {
        filter: blur(20px);
        transform: scale(5);
        opacity: 0;
    }
}


/******************************
* Slow BG Zoom In
*******************************/
.zoom-bg-slow {
    transform: scale(1);
    animation-timing-function: ease-in-out;
    animation-direction: normal;
    animation-name: zoom-bg-slow-anim;
}

@keyframes zoom-bg-slow-anim {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(2);
    }
}


/****************************
* Zoom sequencer
****************************/
.zoom-in-in {
    opacity: 1;
    filter: blur(0px);
    transform: scale(1);
}

.zoom-in-out {
    opacity: 0;
    filter: blur(20px);
    transform: scale(10);
}

@media (prefers-reduced-motion: no-preference) {
    .zoom-in-in {
        animation-timing-function: ease-in-out;
        animation-direction: normal;
        animation-name: zoom-in-in-anim;
    }
    .zoom-in-out {
        animation-timing-function: ease-in-out;
        animation-direction: normal;
        animation-name: zoom-in-out-anim;
    }
}
  
@keyframes zoom-in-in-anim {
    from {
        opacity: 0;
        filter: blur(20px);
        transform: scale(0);
    }
    to {
        opacity: 1;
        filter: blur(0px);
        transform: scale(1);
    }
}

@keyframes zoom-in-out-anim {
    from {
        opacity: 1;
        filter: blur(0px);
        transform: scale(1);
    }
    to {
        opacity: 0;
        filter: blur(20px);
        transform: scale(10);
    }
}


/****************************
* Fade In/Out
****************************/
.fade-in {
    opacity: 1;
}

.fade-out {
    opacity: 0;
}

@media (prefers-reduced-motion: no-preference) {
    .fade-in {
        animation-timing-function: ease-in-out;
        animation-direction: normal;
        animation-name: fade-in-anim;
    }
    .fade-out {
        animation-timing-function: ease-in-out;
        animation-direction: normal;
        animation-name: fade-out-anim;
    }
}

@keyframes fade-in-anim {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-out-anim {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}


.img-zoom-pan {
    --bg-initial-zoom: 1;
    --bg-final-zoom: 4;
    --bg-initial-x: 0%;
    --bg-initial-y: 0%;
    --bg-final-x: 5%;
    --bg-final-y: 10%;
    object-fit: cover;
    transform: scale(var(--bg-final-zoom))
        translate(var(--bg-final-x), var(--bg-final-y));
}

@media (prefers-reduced-motion: no-preference) {
    .img-zoom-pan {
        animation-timing-function: ease-in-out;
        animation-direction: normal;
        animation-name: img-zoom-pan-anim;
    }
}

@keyframes img-zoom-pan-anim {
    from {
        transform: scale(var(--bg-initial-zoom))
            translate(var(--bg-initial-x), var(--bg-initial-y));
    }
    to {
        transform: scale(var(--bg-final-zoom))
            translate(var(--bg-final-x), var(--bg-final-y));
    }
}


/****************************
* Logo Animagion
****************************/
.logo-spin-r1 { transform: rotate(0deg); }
.logo-spin-r2 { transform: rotate(0deg); }
.logo-spin-r3 { transform: rotate(0deg); }
.logo-spin-r4 { transform: rotate(0deg); }
.logo-spin-r5 { transform: rotate(0deg); }

@media (prefers-reduced-motion: no-preference) {
    .logo-spin-r1 {
        animation: logo-spin-anim 6s linear infinite forwards;
    }
    .logo-spin-r2 {
        animation: logo-spin-anim 8s ease-in-out infinite forwards;
    }
    .logo-spin-r3 {
        animation: logo-spin-anim 16s linear infinite forwards;
    }
    .logo-spin-r4 {
        animation: logo-spin-anim 24s linear infinite forwards;
    }
    .logo-spin-r5 {
        animation: logo-spin-anim 48s ease-in-out infinite forwards;
    }
}

@keyframes logo-spin-anim {
    0% { transform: rotate(0); }
    100% { transform: rotate(360deg); }
}


/*** TITLE ANIMATIONS ***/
.title-screen {
    /* background-color: var(--album-bg); */
    background-color: var(--dark);
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}
.title-cover {
    position: relative;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    aspect-ratio: 1;
    object-fit: contain;
    background-color: var(--album-bg);
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(10px);
}
.title-timeline {
    width: 100%;
    height: 100%;
    object-fit: contain;
    /*transform-origin: 0% 50%;*/
    transform-origin: 50% 50%;
}
.title-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    object-fit: contain;
}
.title-logo {
    width: 20%;
    height: 20%;
    aspect-ratio: 1;
    position: absolute;
    bottom: 2.5%;
    left: 2.5%;
}

.final.title-screen {
    background-color: transparent;
}
.final .title-cover {
    background-color: color-mix(in srgb, var(--dark) 25%, transparent);
}
.final .title-timeline {
    opacity: 0;
}

@media (prefers-reduced-motion: no-preference) {
    .animate-out.title-screen {
        animation: titlescreentransition 5s ease-in-out forwards;
    }
    .animate-out .title-cover {
        animation: titlecovertransition 5s ease-in-out forwards;
    }
    .animate-out .title-timeline  {
        animation: timelinetransition 5s ease-in-out forwards;
    }
    .animate-out .title-text {
        animation: texttransition 10s ease-in-out forwards;
    }
    .animate-out .title-logo {
        animation: logotransition 10s ease-in-out forwards;
    }
}

@keyframes timelinetransition {
    0% {  opacity: 1;  transform: scale(100%);}
    25% { opacity: 0.9; transform: scale(100%);}
    /*
    50% { opacity: 0.5; transform: translate(-200%, 0%) rotate(25deg) scale(400%);}
    75% { opacity: 0.5; transform: translate(200%, -100%) rotate(115deg) scale(400%);}
    100% { opacity: 0.5; transform: translate(20%, -50%) rotate(115deg) scale(90%);}
    */
    100% { opacity: 0; transform: scale(100%); }
}

@keyframes texttransition {
    0% {  opacity: 1;  transform: translate(0%, 0%)}
    100% {  opacity: 0; transform: translate(0%, -1000%)}
}

@keyframes logotransition {
    0% {  opacity: 1;  transform: translate(0%, 0%)}
    100% {  opacity: 0; transform: translate(0%, 1000%)}
}

@keyframes titlecovertransition {
    0% { background-color: var(--album-bg); }
    25% { background-color: var(--dark); }
    75% { background-color: var(--dark); }
    100% {
        background-color: var(--dark);
        opacity: .25;
    }
}

@keyframes titlescreentransition {
    0% { background-color: var(--dark); }
    100% { background-color: transparent; }
}